@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes scan {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
  

